<template>
  <div class="accordion" role="tablist">
    <b-card no-body class="border mb-3">
      <b-card-header header-tag="header" role="button" @click="changeVisibilityEachNotif" :aria-controls="unique">
        <b-row>
          <b-col>{{ title }}</b-col>
          <b-col class="text-right">
            <span class="mr-3">{{ formatDate(time) }}</span>
            <em :class="visibleEachNotif ? 'fa fa-angle-up' : 'fa fa-angle-down'"  aria-hidden="true"></em>
          </b-col>
        </b-row>
      </b-card-header>
      <b-collapse :id="unique" v-model="visibleEachNotif">
        <div class="p-3">
          {{ description }}
        </div>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "AnnouncementEvent",
  props: {
    unique: {
      type: String
    },
    time: {
      type: String
    },
    title: {
      type: String
    },
    description: {
      type: String
    }
  },
  data() {
    return {
      visibleEachNotif: true,
    }
  },
  methods: {
    changeVisibilityEachNotif() {
      this.visibleEachNotif = !this.visibleEachNotif
    },
    formatDate(tgl) {
      if (tgl) {
        return moment(tgl).format("DD MMM YY hh:mm");
      }
      return "-";
    },
  }
}
</script>