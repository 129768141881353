<template>
    <div class="dashboard">
     
       <div class="row">
        <b-col lg="12">
           <AnnouncementPublisher/>
        </b-col>
      </div>
      <div class="row mt-2" v-if="items && items.length < 1">
        <div class="col-md-4">
          <b-skeleton-wrapper :loading="isLoading">
              <template #loading>
                <b-card>
                  <b-skeleton width="85%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                </b-card>
              </template>
            </b-skeleton-wrapper>
        </div>
      </div> 
      <div class="row mt-2" v-else>
        <div  class="col-md-4" v-for="(revenue, no ) in latesRevenue(items)" :key="no">
          <b-card :no-body="true" :key="no" class="shadow-lg">
            <b-card-body class="p-0 clearfix">
              <em
                class="fa fa-line-chart bg-success p-4 font-2xl mr-3 float-left"
              ></em>
              <div v-if="!isLoading" class="h5 text-uppercase mb-0 pt-3">
                <em class="fa fa-diamond" v-if="revenue.offer_type=='buffet'"></em>
                <em class="fa fa-book" aria-hidden="true" v-else-if="revenue.offer_type=='Single'"></em>
                {{revenue.offer_type}}
              </div>
              <div v-else class="h6 text-info mb-0 pt-3">
                <b-spinner type="grow" label="Loading..."></b-spinner>
              </div>
  
              <div class="text-muted text-uppercase font-md">
                {{ formatPrice(revenue.total,"IDR")}}
              </div>
               <div class="text-muted  font-xs">
                {{ formatDate(revenue.disbursement_period) }}
              </div>
            </b-card-body>
          </b-card>
        </div>
      </div> 
      <div class="col-md-12">
        <div class="row mt-2 mb-1">
          <div class="col-md-2">
            <h4 class="h4 text-left"> Sales </h4>
            <b-spinner v-if="isLoadingPopular" variant="success" class="align-middle">
              {{ loadChart ? 'Loading ...':''}} 'Loading ...'
            </b-spinner>
            
          </div>
          <div class="col-md-auto">
            <select class="form-control-sm" v-model="showCountMonth">
              <option value="3">3 Month</option>
              <option value="6">6 Month</option>
            </select>
          </div>
          <div class="col-md-auto">
            <i class="fa fa-line-chart btn btn-sm" aria-hidden="true" @click="actionChangeChartType('line')"></i>
            <i class="fa fa-bar-chart btn btn-sm" aria-hidden="true" @click="actionChangeChartType('bar')"></i>
            <i class="fa fa-area-chart btn btn-sm" aria-hidden="true" @click="actionChangeChartType('area')"></i>
          </div>
          <div class="col-md-3"></div>
        </div>
        <div class="row mb-2 pb-1">
          <div class="col-md-9 shadow-sm p-1">
            <RevenueLine 
              :type="typeChart"
              :height="400"
              :options="options"
              :series="series"
            />
          </div>
          <div class="col-md-3 pt-1 pb-1">
            {{ loadChart ? 'Loading ...':''}}
            <div class="card p-2 rounded" v-if="summary">
              <label class="form-control-label text-black">Summary </label>
              <div class="card-body shadow-lg rounded">
                <p>Total : {{ formatPrice(summary.count,"IDR") }}</p>
                <p>Average : {{ formatPrice(summary.average,"IDR") }}</p>
                <p>Median : {{ formatPrice(summary.median,"IDR")}}</p>
                <p>Max : {{ formatPrice(summary.max,"IDR") }}</p>
                <p>Min : {{ formatPrice(summary.min,"IDR") }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="col-md-12 shadow-lg rounded mb-4">
        <h4 class="h4 text-left p-2"> Top 5 Premium Reading </h4>
        <div class="row">
        <b-spinner v-if="isLoadingPopular" class="align-middle">
            {{ isLoadingPopular ? 'Loading ...':''}}
        </b-spinner>
        </div>
        <div class="row" v-if="!isLoadingPopular">
          <div class="col-sm-4" v-for="(item ,no ) in popular" :key="item.id">
            <b-card no-body class="overflow-hidden border-0">
              <b-row no-gutters>
                <b-col sm="4">
                  <b-card-img :src="basePath + removeBucket(item.thumb_image_normal)"  alt="Image" class="rounded-0 cover"></b-card-img>
                </b-col>
                <b-col sm="8">
                  <b-card-body>
                    #{{ no+1 }}
                    <strong>{{ item.item_name.substr(0,50) }} </strong>
                    <b-card-text>
                      <br>
                      Read by {{ item.people }} User
                      <br>
                      of {{ formatSeconToDays(item.duration) }}
                    </b-card-text>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-md-4">
            <b-skeleton-img></b-skeleton-img>
          </div>
          <div class="col-md-4">
            <b-skeleton-img></b-skeleton-img>
          </div>
          <div class="col-md-4">
            <b-skeleton-img></b-skeleton-img>
          </div>
        </div>
      </div> 
  
      <hr>
       <div class="col-md-12 shadow-lg rounded mb-4">
        <h4 class="h4 text-left p-2"> Content Aggregate </h4>
        <div class="row">
          <b-col sm="6" v-if="content.length >0">
            <b-card>
              <ChartContent 
              :title="'Content'" 
              :series="seriesContent"
              :options="optionsContent"
              />
            </b-card>
          </b-col>
          <b-col sm="6" v-if="content">
            <b-card>
              <b-table 
                sticky-header="500px"
                responsive="sm"
                show-empty
                striped
                :busy="isLoading"
                :fields="fields"
                hover 
                :items="content">
                <template #cell(No)="row">
                  {{ row.index+1}}
                </template>
                <template #cell(count)="row">
                  {{ formatPriceBasic(row.item.count)}}
                </template>
                
              </b-table>  
            </b-card>
          </b-col>
        </div>
      </div> 
    </div>
  </template>
  
  <script>
  import { mapState,mapActions} from 'vuex'
  import RevenueLine from '../components/dashboard/revenue'
  import ChartContent from '../components/dashboard/Chartcontent'
  //import Invoice from './reports/invoice.vue';
  import moment from "moment";
  import AnnouncementPublisher from "../components/dashboard/announcementPublisher";
    export default {
      name: "dashboard-vendor",
      components: {
       AnnouncementPublisher,
       RevenueLine,
      ChartContent
      },
      data() {
        return {
          basePath:'https://ebooks.gramedia.com/ebook-covers/',
          options: {},
          series: [],
          summary:{},
          loadChart: true,
          max_period: moment('2023-01-01').format('YYYY-MM-DD'),
          label_chart:[],
          showCountMonth:6,
          typeChart:'line',
          seriesContent:[],
          optionsContent:{},
          fields: [
            { key:'No', label: 'No'},
            { key:'item_type', label: 'Content Type'},
            { key:'item_status', label: 'Status'},
            { key:'count', label: 'Qty'},
          ],
          name:''
        };
      },
      computed: {
        ...mapState({
          items: (state) => state.dashboard.items,
          isLoading: (state) => state.dashboard.isLoading,
          isLoadingPopular: (state) => state.dashboard.isLoadingPopular,
          popular: (state) => state.dashboard.populars,
          content : (state) => state.dashboard.Content,
          isLoadingContent : (state) => state.dashboard.isLoadingContent,
        })
      },
      mounted() {
        this.name = localStorage.getItem("session_name");
        this.actionSetLabelChart()
        this.actionFetch()
        this.actionFetchContent()
      },
      watch: {
        showCountMonth(){
          this.actionSetLabelChart()
          this.actionFetch()
        },
        items() {
          if(this.items && this.items.length > 0){
            this.summaryChart()
            this.actionLoadData()
            this.typeChart = 'line'
            this.loadChart = false
          } else if(!this.isLoading && this.items.length == 0){
            this.loadChart = false
          }
        },
        content() {
          if(this.content.length >0 ){
            this.actionLoadChartContent()
          }
        },
        $route: {
          immediate: true,
          handler(to) {
            document.title = to.meta.title || `Dashbaoard | ` +localStorage.getItem("session_name");
          }
        },
      },
      methods:{
        ...mapActions('dashboard',['FinanceReport','FetchPopularPremiumBook','Content']),
        actionFetchContent(){
          this.Content({}) 
        },
        actionLoadChartContent() {
          if (this.content) {
            this.seriesContent = this.content.map(e => parseInt(e.count,10))
            this.optionsContent= {
              title: {
                text: "Content ",
                align: "left"
              },
              colors: ['#0060AF','#ffec2e','#ff06dc','#0060af','#f4975f','#82a8f4'],
              labels: this.content.map(e => `${e.item_type} (${e.item_status})`),
              dataLabels: {
                enabled: true,
                formatter: function(val) {
                  return parseFloat(val).toFixed(2) + "%";
                }
              },
              plotOptions: {
                pie: {
                  expandOnClick: true,
                  donut: {
                    labels: {
                      show: true,
                      value: {
                        formatter: function(value) {
                          let val = (value / 1).toFixed(0).replace(".", ",");
                          return val
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        actionSetLabelChart() {
          this.label_chart = []
          let startMonth = moment().subtract(this.showCountMonth, 'month').format('YYYY-MM-DD')
          if (startMonth < this.max_period) {
           startMonth = this.max_period
          }
          for (let index = 0; index < this.showCountMonth; index++) {
            let inMonth = moment(startMonth).add(index, 'month').format('YYYY-MM-DD')
            this.label_chart.push(moment(inMonth).endOf('month').format('YYYY-MM-DD'))
          }
        },
        actionFetch(){
          let minPeriod = moment().subtract(this.showCountMonth, 'month').format('YYYY-MM-DD')
          if (minPeriod < this.max_period) {
            minPeriod = this.max_period
          }
          let payload = {
            from: moment(minPeriod).startOf('month').format('YYYY-MM-DD'),
            to: moment().endOf('month').format('YYYY-MM-DD')
          }
          this.FinanceReport(payload);
          this.FetchPopularPremiumBook(payload);
        },
        actionChangeChartType(type) {
          this.typeChart = type
          this.ActionRedender()
        },
        ActionRedender() {
          this.actionLoadData()
        },
        formatPriceBasic(value) {
          let val = (value / 1).toFixed(0).replace(".", ",");
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        formatPrice(value, currency) {
          if (value) {
            let formatter = null;
            if (currency == "USD") {
              formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: currency,
              });
            } else {
              formatter = new Intl.NumberFormat("en-ID", {
                style: "currency",
                currency: currency,
              });
            }
  
            return formatter.format(value);
          }
  
          return "0";
        },
        formatDate(date) {
          return moment(date).format("DD MMMM YYYY");
        },
        latesRevenue(items){
          let current_month = moment().subtract(1, 'months').format('YYYY-MM-DD')
          let data =  items.filter(e => e.disbursement_period == moment(current_month).endOf('month').format('YYYY-MM-DD'))
          if (data && data.length > 0 ) {
            return data
          } else {
            let latestMonth = moment().subtract(2, 'months').format('YYYY-MM-DD')
            return items.filter(e => e.disbursement_period == moment(latestMonth).endOf('month').format('YYYY-MM-DD'))
          }
        }, 
        removeBucket(str) {
          if (str.substring(0, 6) == "images") {
            return str.substring(9);
          } else {
            return str;
          }
        },
        sortByPeriod(items) {
          return items.sort((a, b) => {
            console.log(a.total)
            return moment(a.disbursement_period).unix() - moment(b.disbursement_period).unix();
          });
        },
        FilterOfferType(items){
          let offers = []
          offers.push(items[0].offer_type)
          items.forEach(e => {
            if(offers.indexOf(e.offer_type) == -1){
              offers.push(e.offer_type)
            }
          });
  
          return offers
        },
        formatSeconToDays(duration){
          let days = Math.floor(duration / (24 * 60 * 60));
          let hours = Math.floor((duration - days * 24 * 60 * 60) / (60 * 60));
          let minutes = Math.floor((duration - days * 24 * 60 * 60 - hours * 60 * 60) / 60);
          let seconds = Math.floor(duration - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60);
          return `${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`
        },
        Median(){
          let data = this.items.map(e => e.total)
          return data.sort((a, b) => a - b)[Math.floor(data.length / 2)]
        },
        MinimumDisbursement(){
          let data = this.items.map(e => parseFloat(e.total))
          console.log("data disbursement ",data)
          let min = data[0];
          data.forEach(e => {
            if(e < min){
              min = e
            }
          });
  
          return min
        },
        MaximumDisbursement(){
          let data = this.items.map(e => parseFloat(e.total))
          let max = 0
          data.forEach(e => {
            if(e > max){
              max = e
            }
          });
  
          return max
        },
        Average(){
          let data = this.items.map(e => e.total)
          return data.reduce((a, b) => a + b, 0) / data.length
        },
        Sum(){
          let data = this.items.map(e => e.total)
          return data.reduce((a, b) => a + b, 0)        
        },
        Count(){
          let data = this.items.map(e => e.total)
          return data ? data.length :0
        },
        summaryChart() {
          
          this.summary = {}
          let summary = {
            median : this.Median(),
            average : this.Average(),
            min : this.MinimumDisbursement(),
            max : this.MaximumDisbursement(),
            count : this.Sum()
          }
          this.summary = summary
        },
        DataCart(labels,sales) {
          let data = []
          labels.forEach((e) => {
            if(sales.length > 0){
              let findInLabelMonth = sales.find(s => s.disbursement_period==e)
              if (findInLabelMonth) {
                let amount =findInLabelMonth.total.toFixed(0)
                data.push(amount)
              } else {
                data.push(0)
              }
            } else {
              data.push(0)
            }
          });
          
          return data
        },
        actionSetDiff(data,opt,val) {
          let prev = data[opt.dataPointIndex -1]
          let diff= ((prev - val) / prev) * 100
          if (diff > 0) {
            return `⬇  ${diff.toFixed(2)}%`
          } else {
            diff = Math.abs(diff)
            return `⬆ ${diff.toFixed(2)}%`
          }
        },
        actionLoadData() {
          let item = this.items.filter(e => e.offer_type == 'buffet')
          let single = this.items.filter(e => e.offer_type == 'single')
          let bundle = this.items.filter(e => e.offer_type =='bundle')
          let subscription = this.items.filter(e => e.offer_type =='subscription')
  
          let buffetSales = this.sortByPeriod(item)
          let singleSales = this.sortByPeriod(single)
          let bundleSales = this.sortByPeriod(bundle)
          let subscriptionSales = this.sortByPeriod(subscription)
  
          let labels = this.label_chart
          
          let data = this.DataCart(labels,buffetSales)
          let datasingle = this.DataCart(labels,singleSales)
          let dataBundle = this.DataCart(labels,bundleSales)
          let dataSub = this.DataCart(labels,subscriptionSales)
  
          this.options= {
            chart: {
              type: this.typeChart
            },
            stroke: {
              curve: 'smooth',
              width: [2,2,4]
            },
            markers: {
              size:8
            },
            colors: ['#0060af','#ffec2e','#ff06dc', '#43d14f'],
            dataLabels: {
              enabled: true,
              // colors: ['#fce42d', '#E91E63', '#3664cf','#0060AF'],
              position: 'bottom',
              formatter: function(val,opt) {
                if (opt.seriesIndex == 0 && opt.dataPointIndex > 0 && val >0 ) {
                  let prev = data[opt.dataPointIndex -1]
                  let diff= ((prev - val) / prev) * 100
                  if (diff > 0) {
                    return `⬇  ${diff.toFixed(2)}%`
                  } else {
                    diff = Math.abs(diff)
                    return `⬆ ${diff.toFixed(2)}%`
                  }
                } else if (opt.seriesIndex == 1 && opt.dataPointIndex > 0 && val > 0) {
                  let prev = dataSub[opt.dataPointIndex -1]
                  let diff= ((prev - val) / prev) * 100
                  if (diff > 0) {
                    return `⬇  ${diff.toFixed(2)}%`
                  } else {
                    diff = Math.abs(diff)
                    return `⬆ ${diff.toFixed(2)}%`
                  }
                } else if (opt.seriesIndex == 2 && opt.dataPointIndex > 0 && val > 0) {
                  let prev = datasingle[opt.dataPointIndex -1]
                  let diff= ((prev - val) / prev) * 100
                  if (diff > 0) {
                    return `⬇  ${diff.toFixed(2)}%`
                  } else {
                    diff = Math.abs(diff)
                    return `⬆ ${diff.toFixed(2)}%`
                  }
                } else if (opt.seriesIndex == 3 && opt.dataPointIndex > 0 && val > 0) {
                  let prev = dataBundle[opt.dataPointIndex -1]
                  let diff= ((prev - val) / prev) * 100
                  if (diff > 0) {
                    return `⬇  ${diff.toFixed(2)}%`
                  } else {
                    diff = Math.abs(diff)
                    return `⬆ ${diff.toFixed(2)}%`
                  }
                } 
              }
            },
            xaxis: {
              series:'Buffets',
              categories: labels,
            },
            
            yaxis: {
              labels: {
                show: true,
                position: 'bottom',
                  formatter: function(value) {
                    let formatter = null;
                    formatter = new Intl.NumberFormat("en-ID", {
                      style: "currency",
                      currency: "IDR",
                    });
  
                    return formatter.format(value);
                  }
              }
            },
            plotOptions: {
              candlestick: {
                wick: {
                  useFillColor: true,
                }
              }
            },
          }
          let buf = [{data:data,name:'Buffets',colors:['#43d14f']}]
          let sing = [{data:datasingle,name:'Single'}]
          let bund = [{data:dataBundle,name:'Bundle'}]
          let sub = [{data:dataSub,name:'Subscription',colors:['#fce42d']}]
          let s = sub.concat(sing,bund)
          let series = [...buf,...s]
          console.log("series ",series)
          this.series =series
        }
      }
    }
  </script>
  <style scoped>
  .books {
    font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
  }
  .cover {
    height: auto;
    width: 120px;
    padding:5px;
    margin: 5px 5px;
  }
  .center {
    width:90%;
    margin-left: auto;
    margin-right: auto;
  }
  .primary {
    border: 1px solid #0060af;
  }
  </style>