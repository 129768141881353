<template>
  <div v-if="items.length >0" class="accordion" role="tablist">
    <b-card no-body class="mt-3 mb-3 border">
      <b-card-header header-tag="header" class="text-center" role="button" @click="changeVisibleNotif" aria-controls="accordion-2">
        <b-row>
          <b-col></b-col>
          <b-col class="text-center">News Updates</b-col>
          <b-col class="text-right"><em :class="visibleNotif ? 'fa fa-angle-up' : 'fa fa-angle-down'"  aria-hidden="true"></em></b-col>
        </b-row>
      </b-card-header>
      <b-collapse id="accordion-2" v-model="visibleNotif" class="bg-secondary">
        <div v-if="totalRows > 0" class="pt-3 pl-3 pr-3">
          <AnnouncementEvent
            v-for="(event, index) in items"
            :unique="'each-accordion-'+index"
            :time="event.created"
            :title="event.title"
            :description="event.content"
            :key="index"
          />
        </div>
        <p v-if="totalRows < 1" class="text-center">No announcements yet</p>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
  import AnnouncementEvent from "./announcementEvent";
  import { mapState, mapActions } from "vuex";
  export default {
    name: "AnnouncementPublisher",
    components: {
      AnnouncementEvent
    },
    data() {
      return {
        visibleNotif: true,
      }
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.announcement.isLoading,
        items: (state) => state.announcement.items,
        isError: (state) => state.announcement.isError,
        totalRows: (state) => state.announcement.totalRows,
      }),
    },
    mounted() {
      this.actionFetchAnnouncements();
    },
    methods: {
      ...mapActions("announcement", ["fetchAnnouncementPublisher"]),
      changeVisibleNotif() {
        this.visibleNotif = !this.visibleNotif
      },
      actionFetchAnnouncements() {
        let payload = {
          page: 1,
          limit: 10,
        };
        this.fetchAnnouncementPublisher(payload);
      },
    }
  }
</script>