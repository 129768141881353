var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.items.length > 0 ? _c('div', {
    staticClass: "accordion",
    attrs: {
      "role": "tablist"
    }
  }, [_c('b-card', {
    staticClass: "mt-3 mb-3 border",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "text-center",
    attrs: {
      "header-tag": "header",
      "role": "button",
      "aria-controls": "accordion-2"
    },
    on: {
      "click": _vm.changeVisibleNotif
    }
  }, [_c('b-row', [_c('b-col'), _c('b-col', {
    staticClass: "text-center"
  }, [_vm._v("News Updates")]), _c('b-col', {
    staticClass: "text-right"
  }, [_c('em', {
    class: _vm.visibleNotif ? 'fa fa-angle-up' : 'fa fa-angle-down',
    attrs: {
      "aria-hidden": "true"
    }
  })])], 1)], 1), _c('b-collapse', {
    staticClass: "bg-secondary",
    attrs: {
      "id": "accordion-2"
    },
    model: {
      value: _vm.visibleNotif,
      callback: function ($$v) {
        _vm.visibleNotif = $$v;
      },
      expression: "visibleNotif"
    }
  }, [_vm.totalRows > 0 ? _c('div', {
    staticClass: "pt-3 pl-3 pr-3"
  }, _vm._l(_vm.items, function (event, index) {
    return _c('AnnouncementEvent', {
      key: index,
      attrs: {
        "unique": 'each-accordion-' + index,
        "time": event.created,
        "title": event.title,
        "description": event.content
      }
    });
  }), 1) : _vm._e(), _vm.totalRows < 1 ? _c('p', {
    staticClass: "text-center"
  }, [_vm._v("No announcements yet")]) : _vm._e()])], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }