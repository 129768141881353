var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accordion",
    attrs: {
      "role": "tablist"
    }
  }, [_c('b-card', {
    staticClass: "border mb-3",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    attrs: {
      "header-tag": "header",
      "role": "button",
      "aria-controls": _vm.unique
    },
    on: {
      "click": _vm.changeVisibilityEachNotif
    }
  }, [_c('b-row', [_c('b-col', [_vm._v(_vm._s(_vm.title))]), _c('b-col', {
    staticClass: "text-right"
  }, [_c('span', {
    staticClass: "mr-3"
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.time)))]), _c('em', {
    class: _vm.visibleEachNotif ? 'fa fa-angle-up' : 'fa fa-angle-down',
    attrs: {
      "aria-hidden": "true"
    }
  })])], 1)], 1), _c('b-collapse', {
    attrs: {
      "id": _vm.unique
    },
    model: {
      value: _vm.visibleEachNotif,
      callback: function ($$v) {
        _vm.visibleEachNotif = $$v;
      },
      expression: "visibleEachNotif"
    }
  }, [_c('div', {
    staticClass: "p-3"
  }, [_vm._v(" " + _vm._s(_vm.description) + " ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }